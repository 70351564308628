import { Driver } from "@/interfaces/driver";
import { pusherEvents } from "@/internal";
import { EmptyDriver, NewDriverFormFieldsConfig } from "@/metadata/drivers";
import { driverService } from "@/services/driver.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  DynamicFormComponent,
  FormField,
  FormResponse
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./Driver.template.vue";

@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent
  },
  inject: ["$changes"]
})
export default class DriverComponent extends Vue {
  public model = { ...EmptyDriver };
  public loading = false;
  public isSaving = false;

  @Action("errorRedirect", { namespace: "RouterModule" })
  protected errorRedirect!: Callback;

  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;

  protected id!: string;

  private fieldsConfig: FormField[] = [];

  public async save() {
    const resp: FormResponse = await (this.$refs[
      "drivers-form"
    ] as DynamicFormComponent).submit();

    if (resp.valid) {
      this.isSaving = true;
      this.model = resp.currentModel as Driver;
      const response = this.id
        ? await driverService.put(this.model)
        : await driverService.create(this.model);
      this.isSaving = false;
      if (response) {
        this.cancel();
      }
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async loadDriver() {
    this.id = this.$router.currentRoute.params.id;
    if (this.id) {
      try {
        this.loading = true;
        this.model = await driverService.findById(+this.id);
        this.loading = false;
      } catch (error) {
        this.errorRedirect({
          location: { name: "drivers-list" },
          error
        });
      }
    }
  }

  protected async mounted() {
    this.$changes.watch(pusherEvents.driver, async () => {
      this.isSaving = true;
      await this.loadDriver();
      this.isSaving = false;
    });

    this.setPageNav({
      title: "drivers.title",
      isLoading: () => this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });

    this.fieldsConfig = NewDriverFormFieldsConfig;
    this.loadDriver();
  }
}
