import { Driver } from "@/interfaces/driver";
import { i18n } from "@/plugins/i18n";
import {
  FormField,
  FormFieldTypes,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

export const DriversTableMetadata: TableHeader[] = [
  {
    value: "name",
    fieldComponent: TableFieldType.string,
    label: "first_name",
    sortable: true
  },
  {
    value: "license",
    fieldComponent: TableFieldType.string,
    label: "license_number",
    sortable: true
  },
  {
    value: "license_expiration",
    fieldComponent: TableFieldType.fnsDate,
    label: "expiration_date",
    sortable: true
  }
];

export const NewDriverFormFieldsConfig: FormField[] = [
  {
    label: i18n.t("first_name"),
    name: "firstname",
    component: FormFieldTypes.textField,
    options: { validationRules: ["required"], row: 1, flex: 4 }
  },
  {
    label: i18n.t("last_name"),
    name: "lastname",
    component: FormFieldTypes.textField,
    options: { validationRules: ["required"], row: 1, flex: 4 }
  },
  {
    label: i18n.t("license_number"),
    name: "license",
    component: FormFieldTypes.textField,
    options: { validationRules: ["required"], row: 1, flex: 4 }
  },
  {
    label: i18n.t("expiration_date"),
    name: "license_expiration",
    component: FormFieldTypes.datepicker,
    options: {
      validationRules: ["required"],
      pickerOptions: { required: true, "value-format": "yyyy-MM-dd" },
      row: 2,
      flex: 4
    }
  }
];

export const EmptyDriver: Driver = {
  firstname: "",
  lastname: "",
  license: "",
  license_expiration: ""
};
