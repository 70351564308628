import { policyList } from "@/enums/permissions";
import { Driver } from "@/interfaces/driver";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  ActionSubheader,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

const hasPermission = store.getters["PermissionsModule/hasPermission"];

class DriverService extends HttpService {
  protected uri = "support/drivers";
  protected defaultSort = "";
  protected loadDispatcher = "DriversModule/loadDrivers";

  public async get(query?: { [key: string]: any }): Promise<Driver[]> {
    try {
      const response = await super.get(query);
      return query && query.no_pagination ? response.data : response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async create(driver: Driver) {
    try {
      const response = await super.post(driver);
      messagesService.renderSuccessMessage(
        i18n.t("drivers.created_successfully").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async put(driver: Driver) {
    try {
      const response = await super.put(driver, driver);
      messagesService.renderSuccessMessage(
        i18n.t("drivers.updated_successfully").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async delete(driver: Driver) {
    try {
      const response = await super.delete(driver);
      messagesService.renderSuccessMessage(
        i18n.t("deleted_successfully").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return e;
    }
  }

  public async deleteMultiple(drivers: number[] | undefined[]) {
    try {
      const response = await super.deleteMultiple(drivers);
      messagesService.renderSuccessMessage(
        i18n.t("deleted_successfully").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public generalTableActions(): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        visibleCondition: () => hasPermission(policyList.createDrivers),
        action: () => {
          store.dispatch("RouterModule/go", {
            name: "driver-add"
          });
        }
      }
    ];
  }

  public rowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        visibleCondition: () => hasPermission(policyList.modifyDrivers),
        action: (...arg: Driver[]) => {
          store.dispatch("RouterModule/go", {
            name: "driver-edit",
            params: {
              id: arg[0].id
            }
          });
        }
      },
      {
        icon: "fal fa-trash-alt",
        visibleCondition: () => hasPermission(policyList.deleteDrivers),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("drivers.delete_confirmation").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            store
              .dispatch("DriversModule/removeDriver", arg.item)
              .finally(arg.unselectModal);
          },
          modalCancelText: "No"
        }
      }
    ];
  }

  public getMultiActions(): TableAction[] {
    return [
      {
        icon: "fal fa-trash-alt",
        visibleCondition: () => hasPermission(policyList.deleteDrivers),
        action(arg: any) {
          store.dispatch("DriversModule/removeMultipleDrivers", arg);
        },
        multipleConfirmation: {
          text: i18n.t("table_confirmation.confirmation_text").toString(),
          title: i18n.t("table_confirmation.title").toString()
        }
      }
    ];
  }
}

export const driverService = new DriverService();
